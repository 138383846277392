import axios from "axios"
import { getUser } from "./auth"

const userInfo = getUser()
// console.log(userInfo.authToken)

const API = axios.create({
  // baseURL: "http://localhost/wordpress/wp-json/",
  baseURL: "https://wptest.shoonyavr.com/wp-json/",
  headers: {
    Authorization: `Bearer ${userInfo.authToken}`,
  },
})

export default API
